<template>
  <div>
    <b-input-group
      class="mb-md-0 mb-3"
    >
      <template #prepend>
        <b-input-group-text
          v-if="!dropdownOnly"
          class="prepend-outline-secondary"
          style="background-color: white;"
        >
          {{ label }}
        </b-input-group-text>
      </template>
      <Multiselect
        ref="multiselect"
        v-model="localValue"
        class="form-control" 
        :style="isError ? 'border-color: #ef3451 !important' : ''"
        :placeholder="placeholder"
        :can-deselect="false"
        :close-on-select="true"
        :searchable="false"
        :create-option="false"
        :can-clear="canClear"
        :options="options"
        @click="toggleSelect"
        @close="isOpen = false"
      >
        <!-- Caret  -->
        <template #caret>
          <span class="custom-caret">
            <i class="bx bx-chevron-down my-auto fs-4" />
          </span>
        </template>
        <!-- Selected Option -->
        <template #singlelabel="{ value }">
          <div class="multiselect-single-label">
            <img
              v-if="hasIcon"
              class="me-2"
              :src="value.icon"
              style="max-height: 22px;"
            > 
            {{ value.label }}
          </div>
        </template>
        <!-- Available Options -->
        <template #option="{ option }">
          <img
            v-if="hasIcon && option.icon"
            :src="option.icon"
            class="me-2"
            style="max-height: 22px;"
          >
          <span class="ms-1">
            {{ option.label }}
          </span> 
        </template>
      </Multiselect>
    </b-input-group>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"

export default {
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'Label'
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    dropdownOnly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select an Option'
    },
    options: {
      type: Array,
      default() {
        return [
          { label: 'Option', value: ""}
        ]
      }
    },
  },
  data() {
    return {
      isOpen: false,
      localValue: '',
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.localValue = this.modelValue
      }
    },
    localValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  methods: {
    // Fix multiselect does not close on click like regular dropdown
    toggleSelect() {
      if(this.isOpen) {
        this.$refs.multiselect.close()
      } else {
        this.isOpen = true
        this.$refs.multiselect.open()
      }
    }
  },
}
</script>